.messagesField > label {
  display: block;
  max-width: 150px;
  padding-right: 5px;
  text-align: right;
  margin: 5px 0 10px 0;
}
.message {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
}
.field {}
.field.messageRole {
  min-width: 140px;
  /* font-size: 0.7em; */
}
.field.messageContent {
}
.field.messageDelete input {
  border: 1px solid var(--divider-col);
  padding: 5px 10px 7px 10px;
}
.field.messageDelete input:hover {
  border-color: red;
  color: red;
}

.field.button.messageAdd {
  margin-left: 20px;
}