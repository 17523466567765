
.btn {
  display: inline-block;
  position: relative;
  /* padding: 4px 8px; */
  outline: none;
  border-radius: 8px; /*0.1rem;*/
  cursor: pointer;
  /* color: var(--txt-col); */
  /* background-color: var(--bg-col-content); */
  /* font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; */
  font-size: 0.9rem;
  /* font-weight: 400; */
  line-height: 1.5;
  /* height: calc(1.5em + 0.75rem + 0px); */
  min-height: 1em;
  /* padding: 0.375rem 0.75rem; */
  padding: .6em 1.2em;
  text-transform: none;
  text-shadow: none;
  /* font-weight: 700; */
  line-height: 1em;
  font-style: normal;
  text-align: center;
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
}
.btn:hover {
  /* background: var(--btn-hover-col); */
  filter: brightness(120%); /* saturate(120%); */
}
.btn:active {
  /* background: var(--btn-active-col); */
  filter: brightness(140%); /* saturate(140%); */
  /* border: 1px solid #000;
  color: #000; */
  /* mix-blend-mode: difference; */
  /* color: #fff; */
  background-color: #999;
  /* border: 1px solid #fff; */
}
.btn:focus {
  /* outline: auto; */
}
.btn .txt {
  /* font: bold 20px "Quicksand", san-serif; */
  /* color: #ffffff; */
  transition: all 0.2s;
}

.btn.primary {
  background-color: var(--btn-primary-col);
  border: 1px solid var(--btn-primary-border-col);
  color: var(--btn-primary-txt-col);
}
.btn.primary:hover {
  background-color: var(--btn-primary-col);
  filter: brightness(90%);
}
.btn.primary:active {
  background-color: var(--btn-primary-col);
  filter: brightness(95%);
}

.btn.secondary {
  background-color: var(--btn-secondary-col);
  border: 1px solid var(--btn-secondary-border-col);
  color: var(--btn-secondary-txt-col);
}
.btn.secondary:hover {
  filter: brightness(90%);
}
.btn.secondary:active {
  filter: brightness(95%);
}

.btn.error {
  background-color: var(--btn-delete-col);
  border: 1px solid var(--btn-delete-border-col);
  color: var(--btn-delete-txt-col);
}

.btn.disabled:not(.loading) {
  opacity: 0.45;
  cursor: default;
  pointer-events: none;
}

.btn.fluid {
  width: 100%;
}
.btn.slim {
  padding: 2px 4px;
}

.btn.loading {
  cursor: default;
  pointer-events: none;
}
.btn.loading .txt {
  visibility: hidden;
  opacity: 0;
}
.btn.loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #000; /* TODO: vary this depending on button type? */
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
.btn.error.loading::after {
  border-top-color: var(--btn-delete-txt-col);
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
