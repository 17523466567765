
.projectError {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.view {
  /* min-width: 1000px; */
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.nodeContent {
  /* min-width: 800px; */
  /* flex-grow: 4; */
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.nodeSidebar {
  /* min-width: 200px; */
  /* flex-grow: 1; */
  /* min-width: 300px; */
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-left: 4px solid var(--bg-col-main);
}

.nodeTopBar {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid var(--bg-col-main);
}
.nodeTopBar h1 {
  font-size: 1.2em;
  margin: 0 0 0 10px;
}
.nodeTopBar .editorOptions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.nodeTopBar .editorOptions button {
  padding: 4px 10px;
}
.nodeTopBar .editorOptions .field {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.nodeTopBar .editorOptions .field label {
  display: block;
  min-width: 150px;
  padding-right: 10px;
  text-align: right;
  align-self: center;
}
.nodeTopBar .editorOptions :global(.select) {
  font-size: 1em;
  min-width: 85px;
}

.nodeCanvas {
  flex-grow: 1;
}

.nodeContent .nodeCanvas :global(.react-flow__node.selected) {
  box-shadow: 0 0 0 2px #fff;
  /* border: 1px solid red; */
}

.nodeSidebar .content {
  height: 100%;
  /* min-height: 1000px; */
}

.panelOptions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.actionButtons .saveBtn {}
.actionButtons .runBtn {
  width: 100px;
  height: 100px;
  font-size: 1.6em;
  font-weight: bold;
  padding: 0;
  color: #fff;
  border: 2px solid #fff; /*rgb(139, 3, 3);*/
  background-color: rgb(139, 3, 3); /*rgb(200, 101, 101);*/
  border-radius: 50px;
}
.actionButtons .runBtn.start {}
.actionButtons .runBtn.start.runBtnWithConfirm {
  border-style: dotted;
}
.actionButtons .runBtn.cancel {
  font-size: 1.3em;
  border: 1px solid rgb(139, 3, 3);
  background-color: rgb(200, 101, 101);
}

.runStatus {
  font-size: 0.9em;
  padding: 5px;
  background-color: var(--bg-col-outer);
  border: 1px solid #fff;
  border-radius: 5px;
}
.runStatus .runStatusLbl {
  font-weight: bold;
}
.runStatus.runStatusError {
  font-weight: bold;
  border-color: var(--txt-error-col);
  background-color: #fff;
}
.runStatus .runError {
  color: var(--txt-error-col);
}

.runModal {
}
.runModal .content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.runModal .msg {
  padding: 20px 20px 0 20px;
}
.runModal .msg .simulatedOption {
  display: flex;
  flex-direction: row;
}
.runModal .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 20px;
}
.runModal .warning {
  color: orange;
  padding: 5px 0 0 0;
}

.settingsModal {
  min-width: 600px;
}