/**
 * NB: `box-sizing: border-box;` is set for all elements in the base/core App.css file
 */

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page .header {
  height: var(--header-height);
  background-color: var(--bg-col-outer);
  /* padding: 10px 20px 10px 20px; */

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 16px 20px;
}
.page .header .headerCol {
  flex-grow: 1;
  flex-basis: 0;
  min-height: 20px;
  /* border: 1px solid red; */
}
.page .header .headerCol.headerLeft {
  min-width: var(--col-min-width);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.page .header .headerCol.headerMain {
  flex-grow: 6;
}
.page .header .headerCol.headerRight {
  min-width: var(--col-min-width);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.page .body {
  /* position: relative;
  flex: 1;
  overflow-y: auto; */
  height: calc(100% - var(--header-height) - var(--footer-height));
  display: flex;
  flex-direction: row;
  gap: 6px;
  /* padding: 16px 20px; */
  padding: 10px 10px;
}

/*
.page .body .content {
  height: 100%;
  overflow-y: auto;
  flex-grow: 6;
  background-color: var(--bg-col-content);
  border-radius: 12px;
}
.page .body .sidebar {
  flex-grow: 1;
  flex-basis: 0;
  background-color: var(--bg-col-content);
  border-radius: 12px;
}
.page .body .sidebarLeft {
  min-width: var(--col-min-width);
}
.page .body .sidebarRight {
  min-width: var(--col-min-width);
}
*/

.page .footer {
  height: var(--footer-height);
  background-color: var(--bg-col-outer);
  border-top: 1px solid var(--outline-col);
  padding: 6px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}