
.page {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
}

.form {
  width: 600px;
}

.form h1 {
  text-align: center;
  margin-bottom: 10px;
}

.form .field {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.form .field label {
  display: block;
  width: 250px;
  padding-right: 10px;
  text-align: right;
}
.form .field input {
  font-size: 1em;
  padding: 2px 5px;
}

.form .buttons {
  display: flex;
  justify-content: center;
}
.form .field.button {
  display: inline-block;
}
.form .field.button input {
  padding: 4px 10px;
}
.form.submitting .field.button input[type=submit] {
  position: relative;
}
/* .form.submitting .field.button input[type=submit]:disabled {
  border: 1px solid red;
} */

.form .field.emailField {
  display: none;
}

.form .field.error {
  /* border: 1px solid red; */
}
.form .field.error label {
  color: red;
}
.form .field.error input {
  border: 1px solid red;
}
.form .field.error .errorMsg {
  font-size: 0.8em;
  color: red;
}