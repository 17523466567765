.node {
  /* font-size: 12px; */
  font-size: 0.5em;
  /* padding: 5px; */
  background-color: #fff;
  color: #000;
  border-radius: 3px;
  border: 1px solid #1a192b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  min-width: 150px;
  max-width: 150px;
}
:global(.react-flow__node-userInputNode) {
  border-radius: 3px;
  /* border: 1px solid red; */
}
:global(.react-flow__node-userInputNode.selected) .node {
  /* border: 1px solid red; */
  box-shadow: 0 0 0 2px #fff;
}

.node.startNode {
  border: 1px solid rgb(2, 84, 2);
  background-color: rgb(101, 155, 101);
}
.node.isRunning {
  border: 1px solid rgb(139, 3, 3);
  background-color: rgb(200, 101, 101);
}

.node .content {
  padding: 5px;
  flex-grow: 1;
  word-break: break-all;
}
.node .content .label {
  font-weight: bold;
  font-size: 0.9em;
  padding-right: 2px;
}
.node .content .nodeId {
  display: inline;
  font-weight: bold;
  padding-right: 2px;
}
.node .content .input {
  display: inline;
}

.node .sidebar {
  min-width: 20px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  border-left: 1px solid #1a192b;
}

.node .sidebar .nodeOrder {
  font-size: 0.8em;
  font-weight: bold;
  padding: 0 1px;
  min-width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 1px solid #1a192b;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.node .sidebar .nodeDupe {}
.node .sidebar .nodeDupe .nodeDupeBtn {
  padding: 0px 2px;
  border: 1px solid #1a192b;
  border-radius: 4px;
}
/* .node .sidebar .nodeDupe .nodeDupeBtn:focus {
  outline: none;
}
.node .sidebar .nodeDupe .nodeDupeBtn:active {
  border: 1px solid red;
} */

.node .sidebar .nodeRunning {
  /* border: 1px solid red; */
}

.node .sidebar .nodeError {
  position: relative;
  padding: 0;
  /* border: 1px solid red; */
}
.node .sidebar .nodeError .errorIcon {
  margin: 0;
  padding: 0;
  /* border: 1px solid red; */
}
.node .sidebar .nodeError .errorMsg {
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 2px;
  background-color: #fff;
  border: 1px solid #1a192b;
  border-radius: 4px;
  min-width: 80px;
  z-index: 100000 !important;
}
.node .sidebar .nodeError .errorMsg h4 {
  margin: 0;
}
