.modalWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
}

.modal {
  position:fixed;
  top:50%;
  left:50%;
  height: auto;
  background: var(--bg-col-content);
  border-radius: 12px;
  transform: translate(-50%,-50%);
  z-index: 100;
}
.modal:global(.small) { width: 40%; }
.modal:global(.medium) { width: 60%; }
.modal:global(.large) { width: 80%; }
.modal:global(.fullscreen) { width: 98%; }

.modal .content {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.modal .closeBtn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
.modal .closeBtn:hover {
  cursor: pointer;
  opacity: 0.8;
}
.modal .closeBtn:active {
  opacity: 0.9;
}
