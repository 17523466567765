.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.field {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.field label {
  min-width: 130px;
  text-align: right;
}
.field .input {
  /* border: 1px solid red; */
}

.strictYOrderWIPWarning {
  color: red;

}