
.page {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
}

.errMsg {
  color: red;
  /* font-size: 0.8em; */
  padding: 10px;
}
.successMsg {
  color: green;
  /* font-size: 0.8em; */
  padding: 10px;
}
.errMsg h3, 
.successMsg h3 {
  padding: 0 0 5px 0;
  margin: 0;
}
.errMsg p, 
.successMsg p {
  padding: 0 0 5px 0;
  margin: 0;
}

.form {
  width: 400px;
}

.form h1 {
  text-align: center;
  margin-bottom: 10px;
}

.form .field {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.form .field label {
  display: block;
  width: 120px;
  padding-right: 10px;
  text-align: right;
}
.form .field input {
  font-size: 1em;
  padding: 2px 5px;
}

.form .buttons {
  display: flex;
  justify-content: center;
}
.form .field.button {
  display: inline-block;
}
.form .field.button input {
  padding: 4px 10px;
}
.form.submitting .field.button input[type=submit] {
  position: relative;
}
/* .form.submitting .field.button input[type=submit]:disabled {
  border: 1px solid red;
} */

.form .error {
  color: red;
  text-align: center;
  padding-bottom: 10px;
}
