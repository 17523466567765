.loader {
  /* border: 1px solid red; */
}

.loaderSpin1 {
  color: official;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.loaderSpin1 div {
  transform-origin: 20px 20px;
  animation: loaderSpin1 1.2s linear infinite;
}
.loaderSpin1 div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 18px;
  width: 3px;
  height: 9px;
  border-radius: 10%;
  background: #fff;
}
.loaderSpin1 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.loaderSpin1 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.loaderSpin1 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.loaderSpin1 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.loaderSpin1 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.loaderSpin1 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.loaderSpin1 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.loaderSpin1 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.loaderSpin1 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.loaderSpin1 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.loaderSpin1 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.loaderSpin1 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes loaderSpin1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loaderSpin2 {
  /* width: 48px;
  height: 48px; */
  border: 5px solid #FFF;
  border-bottom-color: #FF3D00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: loaderSpin2 1s linear infinite;
}
@keyframes loaderSpin2 {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.loaderSpin3 {
  color: #ffffff;
  font-size: 45px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: loaderSpin3Anim 1.7s infinite ease, loaderSpin3Round 1.7s infinite ease;
}
@keyframes loaderSpin3Anim {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, 
    -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
     -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, 
     -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
     -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, 
     -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 
    0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes loaderSpin3Round {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}

.loaderSpin4 {
  /* width: 48px;
  height: 48px; */
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #FFF;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: loaderSpin4 1s linear infinite;
}
@keyframes loaderSpin4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
