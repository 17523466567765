.errMsg {
  color: red;
  /* font-size: 0.8em; */
  /* padding: 4px; */
}
.successMsg {
  color: green;
  /* font-size: 0.8em; */
  /* padding: 4px; */
}

.form .checkboxes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 10px;
}
.form .checkboxes .checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
}
.form .checkboxes .checkbox input[type="checkbox"] {
}
.form .checkboxes .checkbox .checkboxTitle {
}

.form .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}