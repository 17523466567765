.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.container > * {
  max-width: 400px;
  width: 100%;
  /* border: 1px solid red; */
}
