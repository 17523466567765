.nodePlayground {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.nodeCanvas {
  min-width: 800px;
  flex-grow: 1;
}
.nodeSidebar {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}


.nodeCanvas :global(.react-flow__node.selected) {
  box-shadow: 0 0 0 2px #fff;
  /* border: 1px solid red; */
}

.nodeSidebar .content {
  min-height: 1000px;
}