.panel {
  height: 100%;
  /* border: 1px solid red; */
}
.panelContent {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 5px;
  padding: 0 5px 5px 5px !important;
}

.panel .property .propertyTitle {
  font-weight: bold;
  padding-right: 5px;
}

.panel .header {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 0 0 0;
  /* flex-basis: 30px; */
  border-bottom: 4px solid var(--bg-col-main);
}

.panel .header .nodeId {
  font-size: 1.2em;
  font-weight: bold;
}
.panel .header .properties {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.panel .properties .property {
}

.panel .propertyInput,
.panel .propertyOutput,
.panel .propertyPromptPreview {
  display: flex;
  flex-direction: column;
}
.panel .propertyInput {
  flex-basis: 30%;
}
.panel .propertyOutput {
  /* flex-basis: 40%; */
  flex-grow: 1;
}
.panel .panelPromptShowing .propertyPromptPreview {
  flex-basis: 30%;
}
.panel .propertyInput .propertyTitle,
.panel .propertyOutput .propertyTitle,
.panel .propertyPromptPreview .propertyTitle {
  padding-bottom: 2px;
}
.panel .propertyPromptPreview .propertyTitle {
  text-decoration: underline;
}
.panel .propertyPromptPreview .propertyTitle:hover {
  cursor: pointer;
}
.panel .propertyInput textarea,
.panel .propertyOutput textarea,
.panel .propertyPromptPreview textarea {
  flex-grow: 1;
  resize: none;
}