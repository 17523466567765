.message {
  padding: 0 10px;
  margin: 10px;
  border-radius: 8px;
  background-color: var(--msg-default-col);
  border: 1px solid var(--msg-default-border-col);
  color: var(--msg-default-txt-col);
}
.message.positive {
  background-color: var(--msg-positive-col);
  border: 1px solid var(--msg-positive-border-col);
  color: var(--msg-positive-txt-col);
}
.message.warning {
  background-color: var(--msg-warning-col);
  border: 2px solid var(--msg-warning-border-col);
  /* color: var(--msg-warning-txt-col); */
}
.message.error {
  background-color: var(--msg-error-col);
  border: 1px solid var(--msg-error-border-col);
  color: var(--msg-error-txt-col);
}

.message p {
  padding: 0;
  margin: 10px 0;
}