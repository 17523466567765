.errMsg {
  color: red;
  font-size: 0.8em;
  padding: 4px;
}
.successMsg {
  color: green;
  font-size: 0.8em;
  padding: 4px;
}

.adminUsers {
  position: relative;
}

table.users {
  border-collapse: collapse;
  /* box-sizing: border-box; */
}
table.users thead tr th {
  padding: 6px 0;
}
table.users tbody tr {
  cursor: pointer;
}
table.users td {
  padding: 4px 10px;
}
table.users td:first-child {
  padding-left: 10px;
}
table.users td:last-child {
  padding-right: 10px;
}
table.users td.name {
  min-width: 100px;
}
table.users tr.selected td {
  background-color: #3e4451;
}
table.users tr.selected td:first-child {
  border-radius: 6px 0 0 6px;
  /* padding-left: 10px; */
}
table.users tr.selected td:last-child {
  border-radius: 0 6px 6px 0;
  /* padding-right: 10px; */
}
table.users tbody tr.deleted {
  color: orange;
}
table.users tbody tr.deleted .successMsg {
  color: orange;
}
table.users tbody tr.deleted td.name,
table.users tbody tr.deleted td.email {
  text-decoration: line-through;
}

table.userInvites {
  border-collapse: collapse;
}
table.userInvites thead tr th {
  padding: 6px 0;
}
table.userInvites tbody tr {
  cursor: pointer;
}
table.userInvites td {
  padding: 4px 10px;
}
table.userInvites td:first-child {
  padding-left: 10px;
}
table.userInvites td:last-child {
  padding-right: 10px;
}
table.userInvites td.actions {
  min-width: 90px;
}
table.userInvites tr.selected td {
  background-color: #3e4451;
}
table.userInvites tr.selected td:first-child {
  border-radius: 6px 0 0 6px;
  /* padding-left: 10px; */
}
table.userInvites tr.selected td:last-child {
  border-radius: 0 6px 6px 0;
  /* padding-right: 10px; */
}

.sidepanel {
  width: 340px;
  box-sizing: border-box;
  min-height: 200px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  background-color: #3e4451;
  border-radius: 6px;
  font-size: 0.9em;
}
.sidepanel h2 {
  padding: 0 4px 4px 4px;
}
.sidepanel .properties {}
.sidepanel .properties .property {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.sidepanel .properties .property .propertyTitle {
  text-align: right;
  padding-right: 10px;
  width: 80px;
  font-weight: bold;
}
.sidepanel .properties .property .propertyValue {
  text-align: left;
  flex-grow: 1;
}

.sidepanel .deleted {
  color: orange;
}

.sidepanel .actions {
  margin-top: 20px;
}
.sidepanel .actions .action {
  padding: 4px 0;
}
.sidepanel .actions .action:last-child {
  padding-bottom: 0;
}
.sidepanel .actions .cancelBtn,
.sidepanel .actions .deleteBtn {
  background-color: transparent;
  border: 1px solid red;
  color: red;
}
.sidepanel .actions .cancelBtn:active,
.sidepanel .actions .deleteBtn:active {
  opacity: 0.8;
}
.sidepanel .actions .action.deleted {
  font-weight: bold;
}