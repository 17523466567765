.userMenu {
  position: relative;
  padding: 5px 0;
  /* border: 1px solid red; */
}

.userMenu .btn {
  cursor: pointer;
}

.userMenu:hover .userMenuDropdown {
  display: block;
}

.userMenu .userMenuDropdown {
  position: absolute;
  top: 28px;
  right: 0px;
  width: 140px;
  background-color: var(--bg-col);
  border: 1px solid #111;
  border-radius: 8px;
  display: none;
  text-align: left;
  z-index: 10;
}
.userMenu .userMenuDropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.userMenu .userMenuDropdown ul li {
  padding: 6px 10px 8px 10px;
}
.userMenu .userMenuDropdown ul li:hover {
  background-color: var(--highlight-col);
}
/* .userMenu .userMenuDropdown ul li:last-child {
  padding-bottom: 10px;
} */