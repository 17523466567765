.select {
  
}

/* React Select Overrides */
.container {
  background-color: var(--bg-col-content) !important;
  border-color: var(--divider-col);
  transition: none;
}
.containerFocused {
  border: none;
  box-shadow: none;
}
.control {
  background-color: var(--bg-col-content) !important;
}
.containerFocused .control {
  border-color: var(--txt-col);
  box-shadow: none;
}
.containerFocused .control:hover {
  border-color: var(--txt-col);
  box-shadow: none;
}
.menu {
  background-color: var(--bg-col-content) !important;
  border: 1px solid var(--divider-col);
}
.option {
  background-color: var(--bg-col-content) !important;
}
.option:hover {
  background-color: var(--bg-col-outer) !important;
}
.indicatorsContainer {
}
.indicatorSeparator {
  /* border: 1px solid red; */
}
.placeholder {
  /* border: 1px solid red; */
}
.valueContainer {
}
.singleValue {
  color: inherit !important;
}

/* Disabled */
.select.disabled .control {
  opacity: 0.5;
}


/* Custom styling */
.optionLabel {
  display: flex;
}

/* Slimline mode */
.select.slimline {
}
.select.slimline .control {
  min-height: auto;
}
.select.slimline .control .valueContainer {
  padding: 0 2px;
  margin: 0;
}
.select.slimline .control .indicatorsContainer {
}
.select.slimline .control .dropdownIndicator {
  padding: 0;
  margin: 0;
}
.select.slimline .control .input {
  padding: 0;
  margin: 0;
}