.node {
  /* font-size: 12px; */
  font-size: 0.5em;
  padding: 5px;
  background-color: #fff;
  color: #000;
  border-radius: 3px;
  border: 1px solid #1a192b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  max-width: 150px;
}
:global(.react-flow__node-userInputNode) {
  border-radius: 3px;
  /* border: 1px solid red; */
}
:global(.react-flow__node-userInputNode.selected) .node {
  /* border: 1px solid red; */
  box-shadow: 0 0 0 2px #fff;
}