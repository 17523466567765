.chatDebug {
  /* height: 100%; */
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
}


.form {
  width: 800px;
}

.form h1 {
  text-align: center;
  margin-bottom: 10px;
}

.form .field {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.form .field label {
  display: block;
  min-width: 150px;
  padding-right: 10px;
  text-align: right;
  align-self: center;
}
.form .field input {
  font-size: 1em;
  padding: 2px 5px;
}
.form :global(.select) {
  font-size: 0.7em;
}
.form input,
.form textarea {
  background-color: var(--bg-col-content);
  border-color: var(--divider-col);
  color: var(--txt-col);
  border-radius: 5px;
  padding: 5px;
  font-family: var(--sans-serif);
  font-size: 0.7em;
}
.form input:focus,
.form textarea:focus {
  outline-width: 0;
  border-width: 2px;
}

.form .buttons {
  display: flex;
  justify-content: center;
}
.form .field.button {
  display: inline-block;
}
.form .field.button input {
  padding: 4px 10px;
}
.form.submitting .field.button input[type=submit] {
  position: relative;
}
/* .form.submitting .field.button input[type=submit]:disabled {
  border: 1px solid red;
} */

.modelSelect {
  min-width: 200px;
}
.fieldPrompt label {
  align-self: flex-start !important;
}
.promptResult {
  margin-bottom: 20px;
}
.promptResult label {
  align-self: flex-start !important;
}

.form .error {
  color: red;
  text-align: center;
  padding-bottom: 10px;
}

.links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
.links a {
  flex: 1; /* equal widths - ref: https://css-tricks.com/equal-columns-with-flexbox-its-more-complicated-than-you-might-think/ */
  flex-grow: 1;
  flex-shrink: 0;
  text-decoration: none;
  font-size: 0.8em;
}
.links a.linkRegister {
  text-align: right;
}
.links a:hover {
  text-decoration: underline;
}
