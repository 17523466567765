.panel {
  box-sizing: border-box;
  /* border: 1px solid #111; */
  border-radius: 6px;
  background-color: var(--bg-col-content);
  display: flex;
  flex-direction: column;
}

.panel .header {
  padding: 10px;
  border-bottom: 1px solid #111;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.panel .header .collapsibleBtn {
  margin-right: 5px;
  /* border: 1px solid red; */
}
/* css arrow - ref: https://www.w3schools.com/howto/howto_css_arrows.asp */
.panel .header .collapsibleBtn i {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 2px;
}
.panel .header .collapsibleBtn.open i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 1px;
}
.panel .header .collapsibleBtn.closed i {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-bottom: 0px;
}
.panel .header h2 {
  flex-grow: 1;
  font-size: 1.2em;
  margin: 0px;
  padding: 0px;
  /* border: 1px solid red; */
}
.panel .header .headerRight {
  padding-left: 5px;
  /* border: 1px solid red; */
}

.panel .content {
  box-sizing: border-box;
  padding: 10px;
  flex-grow: 1;
  overflow: scroll;
}

.panel.closed h2 {
  border: none;
}
.panel.closed .content {
  padding: 0px;
  display: none;
}

/**
 * ArkPanel.Properties
 */
 .panel {
  cursor: default;
}

/**
 * ArkPanel.PropertyRow
 */
.panel .property {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 0 5px 0;
}
.panel .property .title, 
.panel .property .value,
.panel .property .hint {
  border: 0px solid red;
}
.panel .property .title {
  flex-basis: var(--panel-title-width, 100px);
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;
  text-align: right;
  padding-right: 5px;
  font-weight: bold;
  text-transform: capitalize;
  color: #fff;
}
.panel .property.hasChanges .title {
  font-style: italic;
  color: var(--green);
}
.panel .property .value {
  flex-basis: var(--panel-value-width, auto);
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-transform: uppercase; */
}
.panel .property .hint {
  flex-basis: var(--panel-hint-width, 50px);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.panel .property .value :global(.ui.toggle.checkbox label:before) {
  background-color: rgba(255, 255, 255, .20);
}
.panel .property .value :global(.ui.checkbox.disabled label) {
  cursor: not-allowed !important;
  opacity: 0.4;
}
.panel .property .hint {
}

.panel .property .content {
  width: 100%;
  padding: 5px 10px !important;
  line-height: 1.4em;
}

.panel .property .message {
  padding: 5px;
  flex-grow: 1;
}
.panel .property .message :global(.ui.message) {
  margin: 0 !important;
  white-space: break-spaces; /* enables word-wrap */
}

.panel .property .value.editableValue {
  cursor: pointer;
  flex-grow: 20;
}
.panel .property .value.editableValue .valueEdit {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: flex-start;
}
.panel .property .value.editableValue .valueEdit .valueInput {
  max-width: 116px;
  border: none;
}
.panel .property .value.editableValue .valueEdit .valueInput input {
  max-width: 114px;
  padding: 2px;
  line-height: 1;
  height: calc(1.5em + 0.05rem + 2px);
}
.panel .property .value.editableValue .valueEdit .valueEditSave,
.panel .property .value.editableValue .valueEdit .valueEditCancel {
  width: 20px;
  height: 20px;
  margin-top: 4px;
}
.panel .property .value.editableValue .valueEdit .valueEditSave {
  border: 1px solid green;
}
.panel .property .value.editableValue .valueEdit .valueEditCancel {
  border: 1px solid red;
}

/**
 * ArkPanel.PropertyDivider
 */
.propertyDivider {
  border-bottom: 1px solid var(--bd-light);
  margin: 5px 0 5px 0;
}

/**
 * ArkPanel.PropertySection
 */
 .section {
 }
.section > .sectionTitle {
  padding: 5px 10px 0px 10px;
  font-weight: bold;
  user-select: none;
}
.section.sectionCollapsible > .sectionTitle {
  cursor: pointer;
}
.section.sectionCollapsible:not(.open) .sectionTitle {
  padding-bottom: 5px;
}
/* arrow ref: https://www.w3schools.com/howto/howto_css_arrows.asp */
.section:not(.sectionCollapsible) > .sectionTitle > .sectionArrow {
  display: none;
}
.section.sectionCollapsible > .sectionTitle > .sectionArrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 10px;
}
.section.sectionCollapsible > .sectionTitle .sectionArrow.sectionArrowClosed {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.section.sectionCollapsible > .sectionTitle .sectionArrow.sectionArrowOpen {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 2px;
}
 .section.sectionCollapsible > .sectionContent {
  display: none;
  overflow: hidden;
}
.section.sectionCollapsible.open > .sectionContent {
  display: block;
}

/* sub-section (nested sections) - NB: only currently supports 1 level deep/down of nesting */
.section .section {
  padding-left: 20px;
}
.section .section .property .title {
  flex-basis: calc(var(--panel-title-width, 200px) - 20px); /* remove the sub-section padding */
}
