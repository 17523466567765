.content {
  height: 100%;
  /* flex: 1; */
  overflow-y: auto;
  /* padding: 10px; */
  flex-grow: 6;
  background-color: var(--bg-col-content);
  border-radius: 12px;
}
.sidebar {
  flex-grow: 1;
  flex-basis: 0;
  background-color: var(--bg-col-content);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.sidebarLeft {
  min-width: var(--col-min-width);
}
.sidebarRight {
  min-width: var(--col-min-width);
}