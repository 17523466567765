.deleteModal {
  padding: 0 10px 10px 10px;
}

.content {
}

.deleteName {
  font-weight: bold;
}

.deleteForm {
  margin: 0;
}

.successMsg {
  /* padding-bottom: 10px; */
  display: flex;
  flex-direction: column;
}
.successMsgBtn {
  margin: 0 10px;
  align-self: flex-end;
}

.errorMsg {
  margin: 0;
}
