.errMsg {
  color: red;
  /* font-size: 0.8em; */
  padding: 10px;
}
.successMsg {
  color: green;
  /* font-size: 0.8em; */
  padding: 10px;
}
.errMsg h3, 
.successMsg h3 {
  padding: 0 0 5px 0;
  margin: 0;
}
.errMsg p, 
.successMsg p {
  padding: 0 0 5px 0;
  margin: 0;
}