.form {
  margin: 10px 10px 10px 10px;
}

.form .field {
  display: inline-block;
  margin-bottom: 0.5rem;
  /* border: 1px solid red; */
}
.form .field label {
  display: block;
  font-weight: bold;
  font-size: 1em;
  padding: 0 0 0 1px;
  margin: 0 0 5px 0;
}
.form .field .fieldError {
  font-size: 0.8em;
  padding: 5px 0 0 1px;
  color: var(--txt-error-col);
}

.form input {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}

.form .fieldGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.form .fieldGroup:global(.spaceEqual) {
  justify-content: space-between;
}
.form .fieldGroup:global(.spaceEnd) {
  justify-content: flex-end;
}
.form .fieldGroup:global(.padTop) {
  padding-top: 10px;
}
.form .fieldGroup .field:not(:last-child) {
  margin-right: 5px;
}
