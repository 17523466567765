
.page {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
}

.form {
  width: 400px;
}

.form h1 {
  text-align: center;
  margin-bottom: 10px;
}

.form .field {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.form .field label {
  display: block;
  width: 120px;
  padding-right: 10px;
  text-align: right;
}
.form .field input {
  font-size: 1em;
  padding: 2px 5px;
}

.form .buttons {
  display: flex;
  justify-content: center;
}
.form .field.button {
  display: inline-block;
}
.form .field.button input {
  padding: 4px 10px;
}
.form.submitting .field.button input[type=submit] {
  position: relative;
}
/* .form.submitting .field.button input[type=submit]:disabled {
  border: 1px solid red;
} */

.form .error {
  color: red;
  text-align: center;
  padding-bottom: 10px;
}

.links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
.links a {
  flex: 1; /* equal widths - ref: https://css-tricks.com/equal-columns-with-flexbox-its-more-complicated-than-you-might-think/ */
  flex-grow: 1;
  flex-shrink: 0;
  text-decoration: none;
  font-size: 0.8em;
}
.links a.linkRegister {
  text-align: right;
}
.links a:hover {
  text-decoration: underline;
}
