
:root {
  --header-height: 80px;
  --footer-height: 0px;
  --col-min-width: 300px;

  --bg-col-outer: #1f2127;
  --bg-col-main: #282c34;
  --bg-col-content: #1f2127;
  --bg-col: #282c34; /* TODO: OLD - DEPRECIATE */

  --txt-col: #FCFCFD;
  --txt-col-dim: #98A2B3;

  --txt-error-col: #CB0000;

  --btn-primary-col: #120d94;
  --btn-primary-border-col: #4567d3;
  --btn-primary-txt-col: #FCFCFD;

  --btn-secondary-col: #FCFCFD;
  --btn-secondary-border-col: #D0D5DD;
  --btn-secondary-txt-col: #1D2939;

  --btn-success-col: #12B76A;
  --btn-success-border-col: #32D583;
  --btn-success-txt-col: #FCFCFD;

  --btn-delete-col: #FEE4E2;
  --btn-delete-border-col: #CB0000;
  --btn-delete-txt-col: #CB0000;

  --btn-deleted-col: #CB0000;
  --btn-deleted-border-col: #FEE4E2;
  --btn-deleted-txt-col: #FCFCFD;

  --btn-hover-col: #19263e; /* TODO: DEPRECIATE? */
  --btn-active-col: #131d30; /* TODO: DEPRECIATE? */

  --msg-default-col: #FCFCFD50;
  --msg-default-border-col: #FCFCFD;
  --msg-default-txt-col: #FCFCFD;

  --msg-positive-col: #12B76A50;
  --msg-positive-border-col: #12B76A;
  --msg-positive-txt-col: #12B76A;

  --msg-warning-col: #ff973c;
  --msg-warning-border-col: #ff7c09;
  --msg-warning-txt-col: #E2AB1D;

  --msg-error-col: #FEE4E2; /* #CB000050; */
  --msg-error-border-col: #CB0000;
  --msg-error-txt-col: #CB0000;

  --menu-hover: #475467;

  --divider-col: #EAECF0; /* list/table/header divider/bottom-border */

  --outline-col: #FCFCFD; /* TODO: update to new designs or depreciate? */
  --highlight-col: #1c1f24; /* TODO: update to new designs or depreciate? */

  --sans-serif: helvetica, sans-serif;
}


/**
 * enable `border-box` for all elements
 * (don't add padding to the height/dimensions (so 100% height doesn't go beyond the available space etc.))
 * ref: https://css-tricks.com/box-sizing/ 
 */
 html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/**
 * base & common styling
 */
body, html {
  margin: 0;
  padding: 0;
  background-color: var(--bg-col-main);
  color: var(--txt-col);
}
body {
  overscroll-behavior-y: none; /* disable bounce when scrolling at the top/bottom of the page */
  font-family: var(--sans-serif);
}

h1 {
  font-size: 1.4em;
  margin: 0 5px 5px 5px;
}
h2 {
  font-size: 1.2em;
  margin: 0 4px 4px 4px;
}
h3 {
  font-size: 1.05em;
  margin: 0 2px 2px 2px;
}
h4 {
  font-size: 1.0em;
  margin: 0 2px 2px 2px;
}

a {
  color: var(--txt-col);
}

/**
 * core App layout (see BasePage css for the rest)
 */
.App {
  height: 100vh;
}
/* .AppContent {
  height: 100%;
} */

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

