.view {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start;
  align-items: center; */
}

.topbar {
  height: 50px;
  border-bottom: 2px solid var(--bg-col-main);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 5px;
  gap: 10px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.list {
  min-width: 500px;
}
.list ul {
  margin: 10px;
  padding: 0;
  list-style-type: none;
}
.list ul li {
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* border: 1px solid red; */
  border-radius: 3px;
}
.list ul li:hover {
  background-color: var(--bg-col-main);
}
.list ul li .title {
  flex-grow: 1;
  cursor: pointer;
  /* padding: 12px 0; */
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */
}
.list ul li .title a {
  flex-grow: 1;
  padding: 12px 0;
  /* border: 1px solid red; */
}
.list ul li .actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.list ul li .actions .dupe button,
.list ul li .actions .edit button,
.list ul li .actions .delete button {
  padding: 5px 10px;
}

.noProjects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
.noProjectsMsg {
  text-align: center;
}
.noProjectsMsg .title {
  font-weight: bold;
}