.header {
  color: var(--txt-col);
  padding: 20px 20px 20px 24px;
  border-bottom: 1px solid var(--divider-col);
  display: flex;
  flex-direction: row;
}
.header h1,
.header h2,
.header h3,
.header h4 {
  margin: 0;
}
.header .desc {
  display: block;
  color: var(--txt-col-dim);
  font-size: 0.9em;
  padding-top: 5px;
}
.header .headerLeft {
  flex: 1;
}
.header .headerRight {}